import React, { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import './LandingPage.css';

//console.log('LandingPage component is rendering');

console.log('HELLO WORLD')
const link = '';

function LandingPage() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [expandedNewsId, setExpandedNewsId] = useState(null);
  const [showProposed, setShowProposed] = useState({});
  const [editText, setEditText] = useState({});
  const [editedContent, setEditedContent] = useState({});
  const [editHistory, setEditHistory] = useState({});
  const [currentVersion, setCurrentVersion] = useState({});
  const [ratings, setRatings] = useState({});
  const [pendingRatings, setPendingRatings] = useState({});
  const [proposedRatings, setProposedRatings] = useState({});
  const [textReviews, setTextReviews] = useState({});

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await fetch(`${link}/api/news`);
      if (!response.ok) {
        throw new Error('Failed to fetch news');
      }
      const data = await response.json();
      setNews(data);
	 
      // Initialize ratings state with fetched data
      const initialRatings = {};
      data.forEach(item => {
        initialRatings[item.id] = {
          category: item.category_rating,
          editorial: item.editorial_rating,
          importance: item.importance_rating
        };
      });
      setRatings(initialRatings);
      
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const categories = ['All', ...new Set(news.map(item => item.category))];

  const filteredNews = news.filter(item => {
    const categoryMatch = selectedCategory === 'All' || item.category === selectedCategory;
    const itemDate = new Date(item.published_date);
    const dateMatch = (!startDate || itemDate >= new Date(startDate)) && (!endDate || itemDate <= new Date(endDate));
    return categoryMatch && dateMatch;
  });

  const toggleNewsExpansion = (id) => {
    setExpandedNewsId(expandedNewsId === id ? null : id);
  };

  const toggleProposedContent = (id, e) => {
    e.stopPropagation();
    setShowProposed(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleEditSubmit = async (id, e) => {
    e.preventDefault();
    try {
      const newsItem = news.find(item => item.id === id);
      if (!newsItem) {
        throw new Error('News item not found');
      }

      let new_text = [{
        "Original Proposed Response": {
          proposed_title: newsItem.proposed_title,
          proposed_subtitle: newsItem.proposed_subtitle,
          proposed_content: newsItem.proposed_response
        }
      }];

      if (editHistory[id]) {
        new_text = [
          ...new_text,
          ...editHistory[id].map((edit, index) => ({
            [`User's Requested Text ${index + 1}`]: edit.user_text,
            [`Response ${index + 1}`]: {
              proposed_title: edit.proposed_title,
              proposed_subtitle: edit.proposed_subtitle,
              proposed_content: edit.proposed_content
            }
          }))
        ];
      }

      const response = await fetch(`${link}/api/news/edit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          edit_text: editText[id],
          new_text: new_text
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit edit');
      }

      const data = await response.json();
      console.log('Edit processed:', data);

      // Update edit history
      setEditHistory(prev => ({
        ...prev,
        [id]: [...(prev[id] || []), {
          user_text: editText[id],
          ...data.Response
        }]
      }));

      // Update current version
      setCurrentVersion(prev => ({
        ...prev,
        [id]: (prev[id] || 0) + 1
      }));

      // Store the edited content
      setEditedContent(prev => ({
        ...prev,
        [id]: {
          proposed_title: data.Response.proposed_title,
          proposed_subtitle: data.Response.proposed_subtitle,
          proposed_content: data.Response.proposed_content
        }
      }));

      // Reset the edit text for this item
      setEditText(prev => ({ ...prev, [id]: '' }));
    } catch (error) {
      console.error('Error submitting edit:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleVersionChange = (id, version) => {
    const versionContent = editHistory[id][version - 1];
    setEditedContent(prev => ({
      ...prev,
      [id]: {
        proposed_title: versionContent.proposed_title,
        proposed_subtitle: versionContent.proposed_subtitle,
        proposed_content: versionContent.proposed_content
      }
    }));
    setCurrentVersion(prev => ({ ...prev, [id]: version }));
  };

  const handleSubmitChanges = async (id) => {
    try {
      const newsItem = news.find(item => item.id === id);
      if (!newsItem || !editedContent[id]) {
        throw new Error('No edited content to submit');
      }

      const response = await fetch(`${link}/api/news/${id}/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          proposed_title: editedContent[id].proposed_title,
          proposed_subtitle: editedContent[id].proposed_subtitle,
          proposed_content: editedContent[id].proposed_content
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update news item');
      }

      const updatedNewsItem = await response.json();

      // Update the news state with the new content
      setNews(prevNews => prevNews.map(item => 
        item.id === id ? { ...item, ...updatedNewsItem } : item
      ));

      // Clear the edit history for this item
      setEditHistory(prev => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });

      // Clear the edited content for this item
      setEditedContent(prev => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });

      // Clear the current version for this item
      setCurrentVersion(prev => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });

      // Clear the edit text for this item
      setEditText(prev => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });

      alert('Changes submitted successfully!');
    } catch (error) {
      console.error('Error submitting changes:', error);
      alert('Failed to submit changes. Please try again.');
    }
  };

  const handleRating = (id, type, value) => {
    console.log(`Handling rating: id=${id}, type=${type}, value=${value}`);
    setPendingRatings(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [type]: value
      }
    }));
  };

  const handleSubmitRatings = async (id) => {
    const newRatings = pendingRatings[id] || {};
    const currentRatings = ratings[id] || {};

    try {
      const response = await fetch(`${link}/api/news/rate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          category_rating: parseFloat(newRatings.category ?? currentRatings.category ?? 0),
          editorial_rating: parseFloat(newRatings.editorial ?? currentRatings.editorial ?? 0),
          importance_rating: parseFloat(newRatings.importance ?? currentRatings.importance ?? 0),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit rating');
      }

      const data = await response.json();
      console.log('Ratings submitted successfully:', data);
      
      // Update the ratings state and clear pending ratings
      setRatings(prev => ({...prev, [id]: {...prev[id], ...newRatings}}));
      setPendingRatings(prev => {
        const newState = {...prev};
        delete newState[id];
        return newState;
      });

      // Update the news item with the new ratings
      setNews(prevNews => prevNews.map(item => 
        item.id === id ? { ...item, ...data } : item
      ));

    } catch (error) {
      console.error('Error submitting ratings:', error);
      alert('Failed to submit ratings. Please try again.');
    }
  };

  const handleProposedRating = (id, type, value) => {
    setProposedRatings(prev => ({
      ...prev,
      [id]: {
        ...prev[id],
        [type]: value
      }
    }));
  };

  const handleTextReview = (id, value) => {
    setTextReviews(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleSubmitProposedRatings = async (id, version=0) => {
    const ratings = proposedRatings[id] || {};
    const textReview = textReviews[id] || '';
    try {
      const response = await fetch(`${link}/api/news/rate-proposed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
          title_rating: ratings.title || 0,
          subtitle_rating: ratings.subtitle || 0,
          content_rating: ratings.content || 0,
          text_review: textReview,
          version: version,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit proposed content ratings');
      }

      const data = await response.json();
      console.log('Proposed content ratings submitted successfully:', data);

      // Update the news item with the new ratings
      setNews(prevNews => prevNews.map(item => 
        item.id === id ? { ...item, ...data } : item
      ));

      // Clear the pending ratings and text review
      setProposedRatings(prev => {
        const newState = {...prev};
        delete newState[id];
        return newState;
      });
      setTextReviews(prev => {
        const newState = {...prev};
        delete newState[id];
        return newState;
      });

    } catch (error) {
      console.error('Error submitting proposed content ratings:', error);
      alert('Failed to submit proposed content ratings. Please try again.');
    }
  };

  const handleSummarize = async () => {
    try {
      const response = await fetch(`${link}/api/summarize_news`);
      if (!response.ok) {
        throw new Error('Failed to trigger summarize');
      }
      
      console.log('Summarize news triggered');
    } catch (error) {
      console.error('Error triggering summarize:', error);
    }
  };

  const handleReconstruct = async () => {
    try {
      const response = await fetch(`${link}/api/reconstruct_article`);
      if (!response.ok) {
        throw new Error('Failed to trigger reconstruct');
      }
      console.log('Reconstruct article triggered');
    } catch (error) {
      console.error('Error triggering reconstruct:', error);
    }
  };

  const RatingBar = ({ value, onChange, label }) => {
    return (
      <div className="rating-bar">
        <div className="rating-label">{label}</div>
        <div className="rating-track">
          <div 
            className="rating-fill" 
            style={{ width: `${value * 10}%` }}
          />
          <input
            type="range"
            min="0"
            max="10"
            step="0.1"
            value={value}
            onChange={(e) => onChange(parseFloat(e.target.value))}
            className="rating-input"
          />
        </div>
        <div className="rating-value">{value.toFixed(1)}</div>
      </div>
    );
  };

  if (loading) return <div className="loading">Caricamento...</div>;
  if (error) return <div className="error">Errore: {error}</div>;

  return (
    <div className="landing-page">
      <header className="header">
        <h1> . </h1>
        <div className="action-buttons">
          <button className="action-btn" onClick={handleSummarize}>Summarize</button>
          <button className="action-btn" onClick={handleReconstruct}>Reconstruct</button>
        </div>
        <div className="filters">
          <nav className="category-nav">
            {categories.map(category => (
              <button 
                key={category} 
                className={`category-btn ${selectedCategory === category ? 'active' : ''}`}
                onClick={() => setSelectedCategory(category)}
              >
                {category === 'All' ? 'Tutti' : category}
              </button>
            ))}
          </nav>
          <div className="date-filter">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="date-input"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="date-input"
            />
          </div>
        </div>
      </header>
      <main className="news-grid">
        {filteredNews.map((item) => (
          <article key={item.id} className={`news-item ${expandedNewsId === item.id ? 'expanded' : ''}`}>
            <div className="news-item-header">
              <h2 onClick={() => toggleNewsExpansion(item.id)}>{item.title}</h2>
              {expandedNewsId === item.id && (
                <button 
                  className="proposed-content-btn"
                  onClick={(e) => toggleProposedContent(item.id, e)}
                >
                  {showProposed[item.id] ? 'Nascondi Proposta' : 'Mostra Proposta'}
                </button>
              )}
            </div>
            <div className="news-ratings">
              <RatingBar 
                value={pendingRatings[item.id]?.category ?? ratings[item.id]?.category ?? 0}
                onChange={(value) => handleRating(item.id, 'category', value)}
                label="Correttezza categoria"
              />
              <RatingBar 
                value={pendingRatings[item.id]?.editorial ?? ratings[item.id]?.editorial ?? 0}
                onChange={(value) => handleRating(item.id, 'editorial', value)}
                label="Valutazione editoriale"
              />
              <RatingBar 
                value={pendingRatings[item.id]?.importance ?? ratings[item.id]?.importance ?? 0}
                onChange={(value) => handleRating(item.id, 'importance', value)}
                label="Importanza"
              />
              <button 
                className="submit-ratings-btn"
                onClick={() => handleSubmitRatings(item.id)}
              >
                Invia Valutazioni
              </button>
            </div>
            {expandedNewsId === item.id && (
              <div className="news-details">
                <p className="news-context">{item.context}</p>
                <a href={item.url} target="_blank" rel="noopener noreferrer" className="news-url">Leggi l'articolo originale</a>
                {showProposed[item.id] && (
                  <div className="proposed-content">
                    <div className="proposed-section">
                      <h3>Titolo Proposto</h3>
                      <p>{item.proposed_title}</p>
                      <RatingBar 
                        value={proposedRatings[item.id]?.title ?? item.proposed_title_rating ?? 0}
                        onChange={(value) => handleProposedRating(item.id, 'title', value)}
                        label="Valutazione Titolo"
                      />
                    </div>
                    <div className="proposed-section">
                      <h3>Sottotitolo Proposto</h3>
                      <p>{item.proposed_subtitle}</p>
                      <RatingBar 
                        value={proposedRatings[item.id]?.subtitle ?? item.proposed_subtitle_rating ?? 0}
                        onChange={(value) => handleProposedRating(item.id, 'subtitle', value)}
                        label="Valutazione Sottotitolo"
                      />
                    </div>
                    <div className="proposed-section">
                      <h3>Contenuto Proposto</h3>
                      <p>{item.proposed_response}</p>
                      <RatingBar 
                        value={proposedRatings[item.id]?.content ?? item.proposed_content_rating ?? 0}
                        onChange={(value) => handleProposedRating(item.id, 'content', value)}
                        label="Valutazione Contenuto"
                      />
                    </div>
                    <div className="text-review-section">
                      <h3>Recensione Testuale</h3>
                      <textarea
                        value={textReviews[item.id] || ''}
                        onChange={(e) => handleTextReview(item.id, e.target.value)}
                        placeholder="Inserisci qui la tua recensione..."
                        className="text-review-textarea"
                      />
                    </div>
                    <button 
                      className="submit-proposed-ratings-btn"
                      onClick={() => handleSubmitProposedRatings(item.id)}
                    >
                      Invia Valutazioni e Recensione
                    </button>
                    <form onSubmit={(e) => handleEditSubmit(item.id, e)} className="edit-form">
                      <textarea
                        value={editText[item.id] || ''}
                        onChange={(e) => setEditText(prev => ({ ...prev, [item.id]: e.target.value }))}
                        placeholder="Inserisci qui la tua modifica..."
                        className="edit-textarea"
                      />
                      <button type="submit" className="edit-submit-btn">Invia Modifica</button>
                    </form>

                    {editedContent[item.id] && (
                      <div className="edited-content">
                        <div className="edited-content-header">
                          <h3>Versione Modificata</h3>
                          <div className="version-controls">
                            {editHistory[item.id] && editHistory[item.id].length > 1 && (
                              <>
                                <button 
                                  onClick={() => handleVersionChange(item.id, Math.max(1, (currentVersion[item.id] || 1) - 1))}
                                  className="version-btn"
                                  disabled={(currentVersion[item.id] || 1) <= 1}
                                >
                                  &lt;
                                </button>
                                <span className="version-info">
                                  {currentVersion[item.id] || 1} / {editHistory[item.id].length}
                                </span>
                                <button 
                                  onClick={() => handleVersionChange(item.id, Math.min(editHistory[item.id].length, (currentVersion[item.id] || 1) + 1))}
                                  className="version-btn"
                                  disabled={(currentVersion[item.id] || 1) >= editHistory[item.id].length}
                                >
                                  &gt;
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                        <h3>Titolo:</h3>
                        <p>{editedContent[item.id].proposed_title}</p>
                        <RatingBar 
                          value={proposedRatings[item.id]?.title ?? item.proposed_title_rating ?? 0}
                          onChange={(value) => handleProposedRating(item.id, 'title', value)}
                          label="Valutazione Titolo"
                        />
                        <h3>Sottotitolo:</h3>
                        <p>{editedContent[item.id].proposed_subtitle}</p>
                        <RatingBar 
                          value={proposedRatings[item.id]?.subtitle ?? item.proposed_subtitle_rating ?? 0}
                          onChange={(value) => handleProposedRating(item.id, 'subtitle', value)}
                          label="Valutazione Sottotitolo"
                        />
                        <h3>Contenuto:</h3>
                        <p>{editedContent[item.id].proposed_content}</p>
                        <RatingBar 
                          value={proposedRatings[item.id]?.content ?? item.proposed_content_rating ?? 0}
                          onChange={(value) => handleProposedRating(item.id, 'content', value)}
                          label="Valutazione Contenuto"
                        />
                        <div className="text-review-section">
                          <h3>Recensione Testuale</h3>
                          <textarea
                            value={textReviews[item.id] || ''}
                            onChange={(e) => handleTextReview(item.id, e.target.value)}
                            placeholder="Inserisci qui la tua recensione..."
                            className="text-review-textarea"
                          />
                        </div>
                        <button 
                          className="submit-proposed-ratings-btn"
                          onClick={() => handleSubmitProposedRatings(item.id, currentVersion[item.id])}
                        >
                          Invia Valutazioni e Recensione
                        </button>
                        <button 
                          onClick={() => handleSubmitChanges(item.id)}
                          className="submit-changes-btn"
                        >
                          Invia Modifiche
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <div className="news-content">
                  <h3>Contenuto Originale:</h3>
                  <p>{item.text}</p>
                </div>
                <ul className="news-facts">
                  {Array.isArray(item.facts) 
                    ? item.facts.map((fact, i) => <li key={i}>{fact}</li>)
                    : <li>Nessun fatto disponibile</li>
                  }
                </ul>
                <footer className="news-footer">
                  <span className="news-category">{item.category}</span>
                  <span className="news-location">{item.location}</span>
                  <span className="news-date">{new Date(item.published_date).toLocaleDateString()}</span>
                  <span className="news-language">{item.language}</span>
                </footer>
              </div>
            )}
          </article>
        ))}
      </main>
    </div>
  );
}

export default LandingPage;